@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&family=Roboto&display=swap");

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  color: #717986 !important;
  font-style: normal;
  font-weight: 600;
  background-color: #EDF1F5;
  overflow: hidden;
}

.app-content {
  height: calc(100vh - 72px);
  overflow: hidden;
}

a:hover {
  text-decoration: none;
}

/* Error */
.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  line-height: 1.4;
  padding-left: 20px;
  padding-right: 20px;
}

.notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}

.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: .2s all;
  transition: .2s all;
}

/* Forms */
.form-control {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-radius: 0.1rem;
}

.form-control::placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
  /* Firefox */
}

.form-group .label {
  color: #132b50;
  font-size: 12px;
  margin-bottom: 5px;
}

.form-group .label .required {
  color: #dc3545;
}

/* Button */
.btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 0.2rem;
  padding: 6px 10px;
  vertical-align: baseline;
}

.btn-sm {
  font-size: 12px;
  line-height: 10px;
  padding: 6px 10px;
}

.btn-full {
  width: 100% !important;
}

.btn-primary {
  background-color: #2285DD;
  border-color: #2285DD;
  color: #FFFFFF;
}

.btn-add {
  border-color: #fd7e14;
  background-color: #fd7e14;
  color: #ffffff;
  min-width: 100px;
}

.btn-addItem {
  border-color: #146dfd;
  background-color: #146dfd;
  color: #ffffff;
  min-width: 100px;
}

.btn-login {
  border-color: #003652;
  background-color: #003652;
  color: #ffffff;
  min-width: 100px;
}

.btn-refresh {
  border-color: #28a745;
  background-color: #28a745;
  color: #ffffff;
  min-width: 100px;
}

.btn-export {
  border-color: #007bff;
  background-color: #007bff;
  color: #ffffff;
  min-width: 100px;
}

.btn-bulk {
  border-color: #0071a6;
  background-color: #0071a6;
  color: #ffffff;
  min-width: 100px;
  height: 100%;
}

.btn-filter {
  background-color: #ffffff;
  border-color: #5f2b2b;
  color: #5f2b2b;
  padding: 7px 10px;
}

.btn-limit {
  border-color: #DFE0EB;
  background-color: #fff;
  padding: 7px 10px;
  color: #132B50;
}

.btn-apply {
  background-color: #fff;
  border-color: #5e8874;
  color: #5e8874;
  width: 100%;
  height: 26px;
}

.btn-action {
  font-size: 10px;
  padding: 3px 5px;
  margin-right: 5px;
}

.btn-view {
  border-color: #808080;
  background-color: #808080;
  color: #ffffff;
}

.btn-edit {
  border-color: #008080;
  background-color: #008080;
  color: #ffffff;
}

.btn-delete {
  border-color: #dc3545;
  background-color: #dc3545;
  color: #ffffff;
}

.btn-prevnext {
  border-color: #DFE0EB;
  background-color: #fff;
  color: #132B50;
}

.btn-save,
.btn-cancel,
.btn-duplicate,
.btn-submit,
.btn-revise,
.btn-print,
.btn-decline,
.btn-nullify {
  width: 100px;
}

.btn-duplicate {
  background-color: #3E434C;
  color: #ffffff;
}

.btn-submit {
  background-color: #257797;
  color: #ffffff;
}

.btn-revise,
.btn-nullify {
  background-color: #FFD402;
  color: #ffffff;
}

.btn-approve {
  background-color: #4DB851;
  color: #ffffff;
}

.btn-countReverse {
  background-color: #3E434C;
  color: #ffffff;
}

.btn-print {
  background-color: #2285DD;
  color: #ffffff;
}

.btn-decline {
  background-color: #DB2420;
  color: #ffffff;
}

.btn-cancel {
  border-color: #34aee2;
  color: #34aee2;
}

.btn-add:hover,
.btn-refresh:hover,
.btn-action:hover {
  color: #232323;
  text-decoration: none;
}

.btn-bulk:hover {
  color: #f1f1f1;
  text-decoration: none;
}

.btn-filter:hover {
  color: #f1f1f1;
  background-color: #5f2b2b;
  text-decoration: none;
}

.btn-apply:hover {
  color: #f1f1f1;
  background-color: #5e8874;
  text-decoration: none;
}

.btn-save:hover,
.btn-cancel:hover {
  text-decoration: none;
}

.btn-add svg,
.btn-refresh svg,
.btn-export svg {
  margin-right: 5px;
}

.dropdown-menu {
  padding: 0px;
  margin: 0px;
}

.dropdown-item {
  font-size: 12px;
  line-height: 10px;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.dropdown-item-delete:active {
  color: #fff;
  text-decoration: none;
  background-color: #dc3545;
}

.dropdown-toggle::after {
  margin-left: .555em;
  vertical-align: .155em;
}

.limit-menu {
  transform: translate3d(0px, 26px, 0px) !important;
}

/* Login */
.login-card {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  min-width: 400px;
}

.login-header {
  position: absolute;
  width: 138px;
}

.login-card-property {
  position: relative;
  bottom: 45px;
  left: 48px;
}

.login-top-property {
  position: absolute;
  width: 100vw;
  bottom: 190px;
  right: 0;
}

.login-bottom-property {
  position: absolute;
  width: 100vw;
  top: 370px;
  right: 0;
}

.login-slogan {
  width: 170px;
  margin-top: 5px;
}

.login-title {
  font-weight: 500;
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0px;
  color: #132b50;
  text-align: center;
}

.login-failed {
  color: #34AEE2;
  font-weight: 500;
  font-size: 12px;
}

.login-label {
  color: #132B50;
  font-size: 12px;
}

/* Navbar */
.bg-blue {
  background-color: #003652 !important;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, .05);
}

.navbar-brand img {
  width: 200px;
  height: 50px !important;
}

.nav-link {
  font-size: 14px;
  font-weight: 500;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: rgba(113, 121, 134, 0.75) !important;
}

.nav-link-sidebar:focus,
.nav-link-sidebar:hover {
  color: #257797 !important;
  text-decoration: none;
  background-color: #C5F2F6;
}

.nav-link .nav-image {
  margin-right: 10px;
  font-size: 25px;
  text-align: center;
  width: 20px;
  height: 20px;
}

.nav-link.active {
  background-color: rgba(34, 75, 93, 0.15) !important;
}

.nav-link.active span,
.nav-link.active svg {
  color: #0b4963;
}

.notification {
  color: rgba(255, 255, 255, 0.75) !important;
  padding: 8px 0px;
}

.notification-sm {
  display: none;
}

.nav-menu {
  display: none;
}

.nav-dropdown {
  cursor: pointer;
}

.nav-arrow {
  min-width: 16px;
  min-height: 16px;
}

.nav-arrow.down {
  min-width: 16px;
  min-height: 16px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.nav-submenu {
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav-submenu .nav-item .nav-link {
  padding-left: 30px;
}

/* Navbar Profile */
.user-name {
  font-weight: 500 !important;
  color: #ffffff;
}

.user-status {
  font-weight: 400 !important;
  font-size: 12px;
  color: rgba(245, 245, 245);
}

.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  position: relative;
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
}

.avatar img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.dropdown-menu {
  border: 0;
  right: 0;
  padding: 0;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.dropdown-item {
  cursor: pointer;
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 10px 20px;
}

.dropdown-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.dropdown-text {
  font-weight: 400 !important;
  font-size: 12px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #257797;
  text-decoration: none;
  background-color: #C5F2F6;
}

.logout {
  color: rgba(255, 40, 92, 1) !important;
  font-weight: 500 !important;
}

/* Sidebar */
.sidebar {
  height: 100%;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  background-color: #ffffff !important;
  box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.1);
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background-color: #34AEE2;
}

.sidebar-sticky .nav {
  min-height: calc(100vh - 115px);
}

.sidebar-footer {
  border-top: 1px solid #c4c4c4;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  position: sticky;
  z-index: 1020;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.sidebar-item {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 3px 10px;
}

.sidebar-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.sidebar-text {
  font-weight: 400 !important;
  font-size: 12px;
}

/* Main */
.main {
  height: 100%;
  padding: 0px;
  min-height: calc(100vh - 130px);
  overflow: scroll;
}

/* Module */
.module {
  background-color: #D2D5DA;
  padding: 0.75rem;
}

.module-img {
  height: 16px;
  margin-right: 10px;
}

.module-title {
  font-weight: 600;
  font-size: 14px;
}

/* Breadcrumb */
.breadcrumb {
  background-color: #D2D5DA;
  margin: 0px;
  padding: 0px;
}

.breadcrumb-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.breadcrumb-item a:hover {
  text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

/* Content */
.content {
  padding: 20px;
}

/* Search */
.search-div {
  text-align: right;
}

.search {
  padding: 0px;
  text-align: right;
}

.search-label {
  font-weight: 600;
  font-size: 12px;
  color: #495057;
}

.search-input {
  font-size: 12px;
  color: #495057;
  border-radius: 0.25rem;
  padding: .175rem .75rem;
  margin: 0px 5px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

/* Table */
.table-list {
  margin-top: 10px;
}

.table-list td,
.table-list th {
  padding: 0.35rem;
  font-size: 12px;
  font-weight: 300;
  color: #132b50;
}

.table-list thead td,
.table-list thead th,
.table-list tfoot td,
.table-list tfoot th {
  background-color: #2285DD;
  font-size: 13;
  font-weight: 600;
  color: #ffffff;
}

.table-list .header {
  cursor: pointer;
}

/* Add / Edit From */
.detail {
  font-size: 12px;
}

.detail .back-link {
  color: #495057;
  text-decoration: none;
}

.detail .card {
  border: 0px;
}

.detail .module-img {
  height: 20px;
  width: 20px;
}

.detail .card-title {
  color: #fff;
  background-color: #2285DD;
  font-size: 12px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-color: #2285DD;
}

.detail .card-body {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

/* Form */
.required-star {
  margin-left: 2px;
  color: #FF0000;
}

/* Print Modal */
.swal2-title {
  color: #ffffff !important;
  background-color: #132B50 !important;
  padding: .5em 1em !important;
}

.swal2-radio {
  /* display: grid !important; */
  flex-direction: column !important;
  justify-content: space-around !important;
  align-items: stretch !important;
  /* height: 130px !important; */
  padding: 18px !important;
  background-color: #c5f2f68a !important;
  border-radius: 5px;
  border: 1px solid #969696;
  font-weight: 400;
}

.swal2-styled.swal2-cancel {
  border: 1px solid !important;
  background-color: #ffffff !important;
  color: #2285DD !important;
}

.swal2-text-wrapper {
  font-size: 1rem !important;
  text-align: left;
  margin: 16px 0 10px 0;
}

.swal2-checkbox-wrapper {
  max-height: 200px;
  overflow-y: auto;
  background-color: #c5f2f68a;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #969696;
}

.swal2-textarea,
.swal2-decline-textarea {
  font-size: 1rem !important;
  display: flex;
}

.swal2-decline-textarea {
  height: 6.75em;
  padding: 0.75em;
  width: 100% !important;
  margin: 0px !important;
  box-sizing: border-box;
  transition: border-color .1s, box-shadow .1s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%), 0 0 0 3px rgb(0 0 0 / 0%);
  color: inherit;
}

.swal2-decline-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 6%), inset 0 0 0 3px rgb(100 150 200 / 50%) !important;
}

.label-password {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 75%;
  transform: translateY(-50%);
  z-index: 1;
}
